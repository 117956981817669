import footer from "./footer";
import nav from "./nav";

const locales = ["en", "fr", "nl", "de"];

const common = {
  name: "common",
  label: "Common (navbar, footer)",
  files: locales.map((locale) => ({
    name: `common.${locale}`,
    label: `${locale.toUpperCase()}`,
    file: `src/content/common/${locale}.json`,
    fields: [nav, footer],
  })),
};

export default common;
