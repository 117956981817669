import React, { useState, useEffect } from "react";
import { StyleSheetManager } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name
const withStyle = (Component: React.FC<any>) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  props: any
): React.ReactElement | null => {
  const [iframeRef, setIframeRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const iframe = document.getElementsByTagName("iframe")[1];

    if (iframe.contentDocument === null) return;

    setIframeRef(iframe.contentDocument.head);
  }, []);

  if (iframeRef === null) return null;

  return (
    <StyleSheetManager target={iframeRef}>
      <Component {...props} />
    </StyleSheetManager>
  );
};

export default withStyle;
