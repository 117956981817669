const team = {
  name: "team",
  label: "Team",
  widget: "object",
  fields: [
    {
      name: "header",
      label: "Header",
      widget: "object",
      fields: [
        {
          name: "title",
          label: "Title",
          widget: "string",
        },
        {
          name: "background",
          label: "Background",
          widget: "image",
        },
        {
          name: "login",
          label: "Login",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
        {
          name: "apply",
          label: "Apply",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
      ],
    },
    {
      name: "list",
      label: "List",
      widget: "list",
      fields: [
        {
          name: "name",
          label: "Name",
          widget: "string",
        },
        {
          name: "job",
          label: "Job",
          widget: "string",
        },
        {
          name: "linkedin",
          label: "LinkedIn",
          widget: "string",
        },
        {
          name: "quote",
          label: "Quote",
          widget: "text",
        },
        {
          name: "image",
          label: "Image",
          widget: "object",
          fields: [
            {
              name: "src",
              label: "Image",
              widget: "image",
            },
            {
              name: "alt",
              label: "Description",
              widget: "string",
            },
          ],
        },
      ],
    },
  ],
};

export default team;
