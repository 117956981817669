import form from "./form";
import header from "./header";

const locales = ["en", "fr", "nl", "de"];

const apply = {
  name: "apply",
  label: "Apply form",
  files: locales.map((locale) => ({
    name: `apply.${locale}`,
    label: `${locale.toUpperCase()}`,
    file: `src/content/apply/${locale}.json`,
    fields: [header, form],
  })),
};

export default apply;
