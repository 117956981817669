import about from "./about";
import clients from "./clients";
import contact from "./contact";
import services from "./services";
import team from "./team";
import values from "./values";

const locales = ["en", "fr", "nl", "de"];
const sections = [
  { name: "services", fields: services },
  { name: "values", fields: values },
  { name: "clients", fields: clients },
  { name: "team", fields: team },
  { name: "about", fields: about },
  { name: "contact", fields: contact },
];

const files = [];

sections.forEach((s) =>
  locales.forEach((l) => {
    files.push({
      name: `home.${s.name}.${l}`,
      label: `${l.toUpperCase()} | ${
        s.name.charAt(0).toUpperCase() + s.name.slice(1)
      }`,
      file: `src/content/home/${s.name}/${l}.json`,
      fields: [s.fields],
    });
  })
);

const home = {
  name: "home",
  label: "Homepage",
  files,
};

export default home;
