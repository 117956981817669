const services = {
  name: "services",
  label: "Services",
  widget: "object",
  fields: [
    {
      name: "header",
      label: "Header",
      widget: "object",
      fields: [
        {
          name: "title",
          label: "Title",
          widget: "string",
        },
        {
          name: "background",
          label: "Background",
          widget: "image",
        },
        {
          name: "login",
          label: "Login",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
        {
          name: "apply",
          label: "Apply",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
      ],
    },
    {
      name: "body",
      label: "Body",
      widget: "object",
      fields: [
        {
          name: "services",
          label: "Services",
          widget: "object",
          fields: [
            {
              name: "title",
              label: "Title",
              widget: "string",
            },
            {
              name: "description",
              label: "Description",
              widget: "text",
            },
            {
              name: "list",
              label: "List",
              widget: "list",
              fields: [
                {
                  name: "name",
                  label: "Title",
                  widget: "string",
                },
                {
                  name: "logo",
                  label: "Logo",
                  widget: "image",
                },
                {
                  name: "description",
                  label: "Description",
                  widget: "text",
                },
              ],
            },
          ],
        },
        {
          name: "platforms",
          label: "Platforms",
          widget: "object",
          fields: [
            {
              name: "title",
              label: "Title",
              widget: "string",
            },
            {
              name: "list",
              label: "List",
              widget: "list",
              fields: [
                {
                  name: "title",
                  label: "Title",
                  widget: "string",
                },
                {
                  name: "subtitle",
                  label: "Subtitle",
                  widget: "string",
                },
                {
                  name: "description",
                  label: "Description",
                  widget: "text",
                },
              ],
            },
          ],
        },
        {
          name: "formats",
          label: "Formats",
          widget: "object",
          fields: [
            {
              name: "title",
              label: "Title",
              widget: "string",
            },
            {
              name: "description",
              label: "Description",
              widget: "text",
            },
          ],
        },
      ],
    },
  ],
};

export default services;
