const header = {
  name: "header",
  label: "Header",
  widget: "object",
  fields: [
    {
      name: "title",
      label: "Title",
      widget: "string",
    },
    {
      name: "background",
      label: "Background",
      widget: "image",
    },
  ],
};

export default header;
