const nav = {
  name: "nav",
  label: "Navbar",
  widget: "object",
  fields: [
    {
      name: "image",
      label: "Image",
      widget: "object",
      fields: [
        { name: "src", label: "Image", widget: "image" },
        { name: "alt", label: "Description", widget: "string" },
      ],
    },
    {
      name: "services",
      label: "Services",
      widget: "string",
    },
    {
      name: "values",
      label: "Values",
      widget: "string",
    },
    {
      name: "clients",
      label: "Clients",
      widget: "string",
    },
    {
      name: "team",
      label: "Team",
      widget: "string",
    },
    {
      name: "about",
      label: "About",
      widget: "string",
    },
    {
      name: "contact",
      label: "Contact",
      widget: "string",
    },
  ],
};

export default nav;
