import React, { useMemo } from "react";
import { Map as ImmutableMap } from "immutable";
import { IntlProvider } from "react-intl";
import flatten from "flat";
import merge from "lodash/merge";
import { ThemeProvider } from "styled-components";
import theme from "../../theme";
import GlobalStyle from "../../components/GlobalStyle";
import ImagesContext, { Image } from "../../helpers/ImagesContext";
import Locale from "../../types/Locale";
import defaultContent from "../../content/home/services/en.json";
import Services from "../../components/Home/Services";

interface PreviewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: ImmutableMap<string, any>;
  gatsbyImages: Image[];
  locale: Locale;
}

const ServicesPreview: React.FC<PreviewProps> = ({
  entry,
  locale,
  gatsbyImages,
}) => {
  const { mediaFiles } = entry.toJS();

  const images: Image[] = useMemo(
    () =>
      mediaFiles
        .filter((i) => {
          const temp = i.name.split(".");

          if (temp.length !== 2) return false;

          const ext = temp[1];

          if (["png", "jpg", "jpeg", "svg"].includes(ext) === false) {
            return false;
          }

          return true;
        })
        .map((i) => {
          const extension = i.name.split(".")[1];
          let url = i.url;

          if (typeof url !== "string") {
            const gatsbyImage =
              gatsbyImages.find((img) => img.file === i.name) || null;
            if (gatsbyImage !== null) {
              url = gatsbyImage.url;
            }
          }

          return {
            netlify: true,
            file: i.name,
            extension,
            url,
            fluid: null,
          };
        }),
    [mediaFiles, gatsbyImages]
  );

  const content = flatten(merge({}, defaultContent, entry.toJS().data));

  return (
    <IntlProvider defaultLocale={locale} locale={locale} messages={content}>
      <ThemeProvider theme={theme}>
        <ImagesContext.Provider value={images}>
          <GlobalStyle />
          <Services />
        </ImagesContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default ServicesPreview;
