import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import cmsConfig from "../cms";
import withStyle from "../helpers/withStyle";
import ServicesPreview from "../cms/previews/ServicesPreview";
import ValuesPreview from "../cms/previews/ValuesPreview";
import ClientsPreview from "../cms/previews/ClientsPreview";
import TeamPreview from "../cms/previews/TeamPreview";
import AboutPreview from "../cms/previews/AboutPreview";
import ContactPreview from "../cms/previews/ContactPreview";
import useImages from "../helpers/useImages";
import withRouter from "../helpers/withRouter";
import ApplyPreview from "../cms/previews/ApplyPreview";
import CommonPreview from "../cms/previews/CommonPreview";

const locales = ["en", "fr", "nl", "de"];

const AdminPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const gatsbyImages = useImages();

  useEffect(() => {
    if (isLoading === true) return;
    if (isLoading === false) setIsLoading(true);

    const loadCMS = async () => {
      const CMS = (await import("netlify-cms-app")).default;

      CMS.init({
        config: cmsConfig,
      });

      locales.forEach((locale) => {
        CMS.registerPreviewTemplate(
          `home.services.${locale}`,
          withRouter(
            withStyle((props) => (
              <ServicesPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `home.values.${locale}`,
          withRouter(
            withStyle((props) => (
              <ValuesPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `home.clients.${locale}`,
          withRouter(
            withStyle((props) => (
              <ClientsPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `home.team.${locale}`,
          withRouter(
            withStyle((props) => (
              <TeamPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `home.about.${locale}`,
          withRouter(
            withStyle((props) => (
              <AboutPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `home.contact.${locale}`,
          withRouter(
            withStyle((props) => (
              <ContactPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `apply.${locale}`,
          withRouter(
            withStyle((props) => (
              <ApplyPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );

        CMS.registerPreviewTemplate(
          `common.${locale}`,
          withRouter(
            withStyle((props) => (
              <CommonPreview
                {...props}
                locale={locale}
                gatsbyImages={gatsbyImages}
              />
            ))
          )
        );
      });
    };

    loadCMS();
  }, [isLoading, gatsbyImages]);

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="robots" content="noindex" />
      <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
    </Helmet>
  );
};

export default AdminPage;
