const contact = {
  name: "contact",
  label: "Contact",
  widget: "object",
  fields: [
    {
      name: "header",
      label: "Header",
      widget: "object",
      fields: [
        {
          name: "title",
          label: "Title",
          widget: "string",
        },
        {
          name: "background",
          label: "Background",
          widget: "image",
        },
        {
          name: "login",
          label: "Login",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
        {
          name: "apply",
          label: "Apply",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
      ],
    },
    {
      name: "title",
      label: "Title",
      widget: "string",
    },
    {
      name: "form",
      label: "Form",
      widget: "object",
      fields: [
        {
          name: "name",
          label: "Name",
          widget: "string",
        },
        {
          name: "email",
          label: "Email",
          widget: "string",
        },
        {
          name: "phone",
          label: "Phone",
          widget: "string",
        },
        {
          name: "message",
          label: "Message",
          widget: "string",
        },
        {
          name: "button",
          label: "Send button",
          widget: "string",
        },
      ],
    },
    {
      name: "info",
      label: "Info",
      widget: "object",
      fields: [
        {
          name: "company",
          label: "Company",
          widget: "string",
        },
        {
          name: "vat",
          label: "VAT number",
          widget: "string",
        },
        {
          name: "address",
          label: "Address",
          widget: "object",
          fields: [
            {
              name: "street",
              label: "Street",
              widget: "string",
            },
            {
              name: "number",
              label: "Number",
              widget: "string",
            },
            {
              name: "zip",
              label: "Postcode",
              widget: "string",
            },
            {
              name: "city",
              label: "City",
              widget: "string",
            },
            {
              name: "country",
              label: "Country",
              widget: "string",
            },
          ],
        },
        {
          name: "phone",
          label: "Phone",
          widget: "object",
          fields: [
            {
              name: "text",
              label: "Phone number",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
        {
          name: "email",
          label: "Email",
          widget: "object",
          fields: [
            {
              name: "text",
              label: "Email address",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
        {
          name: "open",
          label: "Opening hours",
          widget: "object",
          fields: [
            {
              name: "days",
              label: "Days",
              widget: "string",
            },
            {
              name: "hours",
              label: "Hours",
              widget: "string",
            },
          ],
        },
        {
          name: "maps",
          label: "Google Maps URL",
          widget: "string",
        },
      ],
    },
  ],
};

export default contact;
