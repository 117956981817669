import React from "react";
import {
  createMemorySource,
  createHistory,
  LocationProvider,
} from "@reach/router";

const source = createMemorySource("/");
const history = createHistory(source);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name
const withRouter = (Component: React.FC<any>) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  props: any
): React.ReactElement => {
  return (
    <LocationProvider history={history}>
      <Component {...props} />
    </LocationProvider>
  );
};

export default withRouter;
