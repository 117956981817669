const form = {
  name: "form",
  label: "Form",
  widget: "object",
  fields: [
    {
      name: "details",
      label: "Details",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "inputs",
          label: "Fields",
          widget: "object",
          fields: [
            {
              name: "firstname",
              label: "First name",
              widget: "string",
            },
            {
              name: "lastname",
              label: "Last name",
              widget: "string",
            },
            {
              name: "email",
              label: "Email",
              widget: "string",
            },
          ],
        },
      ],
    },
    {
      name: "native",
      label: "Native language",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "placeholder",
          label: "Placeholder",
          widget: "string",
        },
      ],
    },
    {
      name: "source",
      label: "Source language",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "placeholder",
          label: "Placeholder",
          widget: "string",
        },
      ],
    },
    {
      name: "languages",
      label: "Languages",
      widget: "list",
    },
    {
      name: "tools",
      label: "CAT tools",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "placeholder",
          label: "Placeholder",
          widget: "string",
        },
        {
          name: "options",
          label: "Options",
          widget: "list",
        },
      ],
    },
    {
      name: "services",
      label: "Services",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "options",
          label: "Options",
          widget: "list",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "value",
              label: "Value",
              widget: "hidden",
            },
          ],
        },
      ],
    },
    {
      name: "resume",
      label: "Resume",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "placeholder",
          label: "Placeholder",
          widget: "string",
        },
      ],
    },
    {
      name: "letter",
      label: "Cover letter",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "placeholder",
          label: "Placeholder",
          widget: "string",
        },
      ],
    },
    {
      name: "comments",
      label: "Comments",
      widget: "object",
      fields: [
        {
          name: "label",
          label: "Label",
          widget: "string",
        },
        {
          name: "placeholder",
          label: "Placeholder",
          widget: "string",
        },
      ],
    },
    {
      name: "submit",
      label: "Submit button",
      widget: "string",
    },
  ],
};

export default form;
