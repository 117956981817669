const footer = {
  name: "footer",
  label: "Footer",
  widget: "object",
  fields: [
    {
      name: "image",
      label: "Image",
      widget: "object",
      fields: [
        { name: "src", label: "Image", widget: "image" },
        { name: "alt", label: "Description", widget: "string" },
      ],
    },
    {
      name: "about",
      label: "About",
      widget: "object",
      fields: [
        { name: "text", label: "Label", widget: "string" },
        { name: "href", label: "Anchor / URL", widget: "string" },
        {
          name: "links",
          label: "Links",
          widget: "list",
          fields: [
            { name: "text", label: "Label", widget: "string" },
            { name: "href", label: "Anchor / URL", widget: "string" },
          ],
        },
      ],
    },
    {
      name: "account",
      label: "Account",
      widget: "object",
      fields: [
        { name: "text", label: "Label", widget: "string" },
        { name: "href", label: "Anchor / URL", widget: "string" },
        {
          name: "links",
          label: "Links",
          widget: "list",
          fields: [
            { name: "text", label: "Label", widget: "string" },
            { name: "href", label: "Anchor / URL", widget: "string" },
          ],
        },
      ],
    },
    {
      name: "contact",
      label: "Contact",
      widget: "object",
      fields: [
        { name: "text", label: "Label", widget: "string" },
        { name: "href", label: "Anchor / URL", widget: "string" },
      ],
    },
    {
      name: "social",
      label: "Social",
      widget: "object",
      fields: [
        { name: "facebook", label: "Facebook", widget: "string" },
        { name: "linkedin", label: "LinkedIn", widget: "string" },
      ],
    },
  ],
};

export default footer;
