const about = {
  name: "about",
  label: "About",
  widget: "object",
  fields: [
    {
      name: "header",
      label: "Header",
      widget: "object",
      fields: [
        {
          name: "title",
          label: "Title",
          widget: "string",
        },
        {
          name: "background",
          label: "Background",
          widget: "image",
        },
        {
          name: "login",
          label: "Login",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
        {
          name: "apply",
          label: "Apply",
          widget: "object",
          fields: [
            {
              name: "label",
              label: "Label",
              widget: "string",
            },
            {
              name: "link",
              label: "Link",
              widget: "string",
            },
          ],
        },
      ],
    },
    {
      name: "title",
      label: "Title",
      widget: "string",
    },
    {
      name: "description",
      label: "Description",
      widget: "text",
    },
    {
      name: "list",
      label: "List",
      widget: "list",
      fields: [
        {
          name: "title",
          label: "Title",
          widget: "string",
        },
        {
          name: "description",
          label: "Description",
          widget: "text",
        },
      ],
    },
  ],
};

export default about;
