import { CmsConfig } from "netlify-cms-core";
import collections from "./collections";

interface FixedCmsConfig extends CmsConfig {
  local_backend: boolean;
}

const config: FixedCmsConfig = {
  backend: {
    name: "git-gateway",
    branch: "master",
  },
  local_backend: true,
  media_folder: "src/assets",
  public_folder: "",
  collections,
};

export default config;
